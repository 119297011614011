<template>
    <div class="Edit" style="bottom:0;">
        <ot-edit ref="otEdit" v-if="moduleCode!=''" :moduleCode="moduleCode" :currency="currency" :AONIcustSort="AONIcustSort" :sysBoxValue="sysBoxValue" :cusBoxValue="cusBoxValue" :billId="billId"></ot-edit>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import otEdit from '@/page/Client/CustPublic/optVue/otEdit/index'
export default {
    name: 'Edit',
    title: '编辑',
    props: {
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        AONIcustSort: {
            type: Array,
            default: function () {
                return []
            }
        },
        currency: {
            type: Array,
            default: function () {
                return []
            }
        },
        cusBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            msg: 'add',
            pageUrl: '',
            moduleCode: '',
            billId: ''
        }
    },
    created() {
    },
    mounted() {
        this.moduleCode = this.$route.params.moduleCode
        this.billId = this.$route.params.id
        this.setMenu()
    },
    methods: {
        ...mapMutations({
            set_closeYes: 'SET_CLOSEYES'
        }),
        setMenu() {
            if (this.moduleCode == 'BF001') {
                this.setHeaderTitle(this.$t('mx_mobile.Client.1585303865842')) // 编辑客户
            } else if (this.moduleCode == 'BF003') {
                this.setHeaderTitle(this.$t('mx_mobile.Client.1585303880411')) // 编辑联系人
            } else if (this.moduleCode == 'BF004') {
                this.setHeaderTitle(this.$t('mx_mobile.Client.1585303881179')) // 编辑跟进
            } else {
                this.setHeaderTitle(this.$t('mx_mobile.Client.1585303753979')) // 编辑
            }
            setTimeout(() => {
                this.Global.utils.rightMenu.clearMenu()
            }, 100)
        }
    },
    computed: {
        ...mapGetters(['closeYes'])
    },
    beforeRouteLeave(to, from, next) {
        const flag = this.Global.utils.chosen.close()
        const flag2 = this.Global.utils.actionSheet.close()
        const flag3 = this.Global.utils.prompt.close()
        if (flag || flag2 || flag3) {
            next(false)
        } else if (this.closeYes) {
            this.$dialog.confirm({
                title: this.$t('mx_mobile.Client.1585304004347'), // 提示
                message: this.$t('mx_mobile.Client.1585304000139'), // '已编辑内容将不会保存，是否确定离开？'
                closeOnPopstate: false
            }).then(() => {
                next()
            }).catch(() => {
                next(false)
            })
        } else {
            this.set_closeYes(true)
            next()
        }
    },
    watch: {
        '$route': function (val, old) {
            if (val.path.indexOf('/client/edit') != -1) {
                this.moduleCode = val.params.moduleCode
                this.setMenu()
                this.billId = this.$route.params.id
                let _this = this
                setTimeout(() => {
                    _this.$refs.otEdit.update()
                }, 20)
            }
        }
    },
    components: {
        'ot-edit': otEdit
    }
}
</script>

<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
